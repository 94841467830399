// 3rd
import { z } from 'zod';

// App - Other
import { castCodeSourceDtoToCodeSource, CodeSourcesDto } from '@/types/integration/dtos';
import {
  ApplicationProgrammingLanguagesDto,
  castApplicationProgrammingLanguageDtoToApplicationProgrammingLanguage,
} from '@/types/application/dtos';
import {
  castRequirementCodeImplementationStatusDtoToRequirementCodeImplementationStatus,
  RequirementCodeImplementationStatusesDto,
} from '@/types/story/implementation-analysis/dtos';
import { CodeFileRequirementCoverage } from '../../../types/code-file-requirement-coverage';

export const ZodCodeFileRequirementCoverageDto = z.object({
  codeFilePath: z.string(),
  codeSourceType: z.enum(CodeSourcesDto),
  originUrl: z.string(),
  programmingLanguage: z.enum(ApplicationProgrammingLanguagesDto),
  repositoryId: z.string(),
  requirementId: z.string(),
  implementationStatus: z.enum(RequirementCodeImplementationStatusesDto),
});

export type CodeFileRequirementCoverageDto = z.infer<typeof ZodCodeFileRequirementCoverageDto>;

export const castCodeFileRequirementCoverageDtoToCodeFileRequirementCoverage = (
  dto: CodeFileRequirementCoverageDto
): CodeFileRequirementCoverage => {
  return {
    codeFilePath: dto.codeFilePath,
    codeSourceType: castCodeSourceDtoToCodeSource(dto.codeSourceType),
    implementationStatus:
      castRequirementCodeImplementationStatusDtoToRequirementCodeImplementationStatus(
        dto.implementationStatus
      ),
    originUrl: dto.originUrl,
    programmingLanguage: castApplicationProgrammingLanguageDtoToApplicationProgrammingLanguage(
      dto.programmingLanguage
    ),
    repositoryId: dto.repositoryId,
    requirementId: dto.requirementId,
  };
};
